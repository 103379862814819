<template>
    <div class="row justify-content-center" v-if="loading">
        <div class="col-sm-5 text-center pt-2">
            <font-awesome-icon icon="spinner" spin size="3x"></font-awesome-icon>
            <p class="text-center my-1">Loading...</p>
        </div>
    </div>
    <div v-else>
        <div v-if="!access">
            <NotFound />
        </div>
        <div v-else>
            <div class="pb-2 mb-3 border-bottom">
                <h2>My Comp Offs</h2>
            </div>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <router-link to="/">Dashboard</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">My Comp Offs</li>
                </ol>
            </nav>
            <b-container fluid>
                <div v-if="api_error">
                    <b-alert
                        :show="alertDismissCountDown"
                        dismissible
                        variant="danger"
                        @dismissed="alertDismissCountDown=0"
                        @dismiss-count-down="alertCountDownChanged"
                    >{{api_error}}</b-alert>
                </div>
                <b-row>
                    <b-col lg="2" sm="12" class="mb-1rem">
                        <div class="form-group row">
                            <label
                                class="col-lg-3 col-md-2 col-sm-12 col-form-label text-nowrap"
                            >Year</label>
                            <div class="col-lg-9 col-md-10 col-sm-12">
                                <b-form-select
                                    v-model="urlBody.year"
                                    :options="years"
                                    disabled-field="notEnabled"
                                ></b-form-select>
                            </div>
                        </div>
                    </b-col>
                    <b-col lg="1">
                        <button
                            type="button"
                            class="btn btn-primary text-right "
                            v-on:click="getCompOffs(urlBody)"
                        >Filter</button>
                    </b-col>
                    <b-col lg="5" class="mb-1rem">
                        <router-link
                            :to="{name: 'apply-compoff'}"
                            class="btn btn-primary text-right"
                            tag="button"
                        >
                            <font-awesome-icon icon="plus"></font-awesome-icon>&nbsp; Apply Comp Off
                        </router-link>
                    </b-col>
                </b-row>

                <b-table
                    show-empty
                    striped
                    hover
                    :items="items"
                    :fields="fields"
                    no-local-sorting
                    @sort-changed="customSorting"
                    responsive
                >

                <template v-slot:cell(type)="row">
                    <span>{{str_title(row.item.type)}}</span>
                </template>
                <template v-slot:cell(actions)="row">
                        <span v-if="row.item.status == 'APPLIED'" class="ml-2">
                            <font-awesome-icon
                                style="cursor:pointer;font-size:17px"
                                @click="info(row.item, row.index, $event.target)"
                                icon="times"
                                class="mr-1 text-danger"
                                v-b-tooltip.hover title="Cancel"
                            ></font-awesome-icon>
                        </span>  
                </template>

                <template v-slot:cell(status)="row">
                        <span v-if="row.item.status == 'APPLIED'"><b-badge variant="info">{{row.item.status}}</b-badge></span>
                        <span v-else-if="row.item.status == 'APPROVED'"><b-badge variant="success">{{row.item.status}}</b-badge></span>
                        <span v-else-if="row.item.status == 'REJECTED'"><b-badge variant="danger">{{row.item.status}}</b-badge></span>
                        <span v-else><b-badge variant="dark">{{row.item.status}}</b-badge></span>
                    </template>
                </b-table>
            </b-container>

            <!-- Info modal -->
            <b-modal
                :id="infoModal.id"
                :title="infoModal.title"
                centered
                size="md"
                @hide="resetInfoModal"
            >
                <p v-html="message" class="float-left"></p>
                <div slot="modal-footer" class="w-100">
                    <b-button class="mt-3 float-right" @click="cancel()">No</b-button>
                    <b-button
                        class="mt-3 float-right"
                        variant="danger"
                        v-on:click="cancelCompOff(infoModal.item_id)"
                        style="margin-right:10px;"
                    >Yes</b-button>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            urlBody: {
                query: "",
                sort_by: "created_at",
                order: "desc",
                year: new Date().getFullYear(),
            },
            api_error: "",
            sortBy: "id",
            sortDesc: false,
            fields: [
                { key: "date", label: "Applied Date", sortable: true,
                    formatter: value => {
                        return this.dateformat(value);
                    }
                },
                { label: 'Comp Off Type', key: "type", sortable: true},
                { key: "reason" ,tdClass: 'reasonClass'},
                { key: "status",sortable: true},
                { key: "actions", label: "Actions" }
            ],
            items: null,
            totalRows: 1,
            access: true,
            loading: true,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            infoModal: {
                id: "modal-sm",
                title: "",
                item_id: ""
            },
            message:"",
            years: [],
            total_years:2,
        };
    },
    mounted() {
        this.getEmployee();
        this.getYears();
    },
    methods: {
        getYears() {
            const currentYear = (new Date()).getFullYear();
            for (let index = 0; index < this.total_years; index++) {
                this.years.push({
                    value: currentYear-index, text:currentYear-index
                })
            }
        },

        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },

        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        info(item, index, button) {
            this.infoModal.title = `Cancel Confirmation`;
            this.message = 'Are you sure you want to Cancel this CompOff?';
            this.infoModal.item_id = item.id;
            this.$root.$emit("bv::show::modal", this.infoModal.id, button);
        },
        resetInfoModal() {
            this.infoModal.title = "";
            this.infoModal.item_id = "";
        },

        cancel: function() {
            this.$root.$emit("bv::hide::modal", this.infoModal.id);
        },

        cancelCompOff(id) {
            this.startProgressBar();
            this.axios.post(this.$api.cancel_compoffs, {id:id}, this.getAuthHeaders()).then(() => {
                this.$root.$emit("bv::hide::modal", this.infoModal.id);
                this.api_error = "";
                this.getCompOffs();
                this.updateProgressBar(true);
            })
            .catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.updateProgressBar(false);
                this.showAlert();
            });
        },

        convertToDate(D){
            var date = new Date(D);
            return ('0' + date.getDate()).slice(-2) + '-' + ('0' + (date.getMonth()+1)).slice(-2) + '-'+ date.getFullYear();
        },

        getDefaultFilters() {
            let api_params = {
                page: this.urlBody.page,
                per_page: this.urlBody.per_page,
                sort_by: this.urlBody.sort_by,
                order: this.urlBody.order,
                year: this.urlBody.year,
            };
            return api_params;
        },
        
        getEmployee: function () {
            let query = this.getAuthHeaders();
            this.axios.get(this.$api.get_employees, query).then(response => {
                let my_compoffs = response.data.data.leave_group.compoff;
                if (my_compoffs && my_compoffs.comp_off_holidays && my_compoffs.comp_off_week_offs || (my_compoffs.comp_off_holidays.length !== 0) || (my_compoffs.comp_off_week_offs.length !== 0)){
                   this.getCompOffs(this.getDefaultFilters());
                } else {
                    this.access = false;
                }
                this.loading = false;
            })
            .catch(err => {
                this.loading = false
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.showAlert();   
            });
        },

        getCompOffs: function(params) {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = this.deleteEmptyKeys(params);
            this.axios.get(this.$api.get_compoffs, query).then(response => {
                const result = response.data.data;
                this.items = result;
                this.api_error = "";
                this.updateProgressBar(true);
                this.$router.push({
                    path: this.$route.params[0],
                    query: this.deleteEmptyKeys(params)
                }).catch(()=>{})
            }).catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.items = [];
                this.updateProgressBar(false);
                this.showAlert();
            });
        },

        customSorting: function(ctx) {
            if (ctx.sortBy == "" || ctx.sortBy == null) {
                return;
            }
            let urlBody = this.deleteEmptyKeys(this.$route.query);
            urlBody.order = ctx.sortDesc ? "asc" : "desc";
            urlBody.sort_by = ctx.sortBy;
            urlBody.page = 1;
            this.api_error = "";
            this.getCompOffs(urlBody);
        },
        
        dateformat (date) {
            var dateFormat = new Date(date);
            var dd = String(dateFormat.getDate()).padStart(2, '0');
            var mm = String(dateFormat.getMonth() + 1).padStart(2, '0');
            var yyyy = dateFormat.getFullYear();
            return dd + '-' + mm + '-' + yyyy;
        },
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
</style>

<style >
.reasonClass {
    width:15% !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 1px;

}
</style>